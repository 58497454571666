<template>
  <div
    class="checkbox"
    :class="{ full: useFullWidth }"
  >
    <input
      v-bind="$attrs"
      :id="id"
      v-model="innerValue"
      :name="name"
      type="checkbox"
      class="checkbox__input"
      @change="onChange"
    >
    <label
      class="checkbox__label-wrapper"
      :for="id"
    >
      <div :class="['checkbox__icon', { 'checkbox__icon--checked': innerValue }]">
        <CheckIcon
          v-if="innerValue"
          :variant="innerValue ? 'success' : 'transparent'"
          :size="16"
          class="checkbox__icon__check"
        />
      </div>
      <Tooltip
        v-show="!isValid"
        :content="tooltipContent"
        :TranslatableContentKey="tooltipTranslatableContentKey"
        class="checkbox__icon__warning"
      />
      <span :class="['checkbox__label', { checkbox__invalid: !isValid }]">
        <slot />
      </span>
    </label>
  </div>
</template>

<script lang="ts">
import Tooltip from '../Tooltip.vue'
import CheckIcon from '../icons/Check.icon.vue'

export default defineNuxtComponent({
  components: {
    Tooltip,
    CheckIcon,
  },
  props: {
    id: { type: String, required: true},
    name: {type: String, default: () => ''},
    modelValue: { type: Boolean, required: false, default: false },
    tooltipContent: { type: String, default: ''},
    tooltipTranslatableContentKey: { type: String, default: ''},
    useFullWidth: { type: Boolean, default: true },
    isValid: { type: Boolean, default: true, required: false },
  },
  setup(props, {emit}) {
    const innerValue = ref(props.modelValue)

    function onChange() {
      emit('update:modelValue', innerValue.value)
    }

    return {
      innerValue,
      onChange,
    }
  }
})
</script>

<style lang="scss" scoped>
.full {
  @apply w-full;
}
.checkbox {
  &__label-wrapper {
    @apply cursor-pointer;
    @apply flex items-center;
  }

  &__invalid {
    @apply border-b-2;
    @apply border-solid;
    @apply border-yellow-300;
  }

  &__input {
    @apply hidden;
  }

  &__icon {
    $size: 24px;
    width: $size;
    height: $size;
    @apply inline-flex;
    @apply justify-center items-center;
    @apply rounded-md;
    @apply border;
    @apply border-spl-secondary-dark;
    @apply transition-colors;

    @apply mr-2;

    flex-shrink: 0;

    &--checked {
      @apply border-spl-gray-1;
    }
    &__warning {
      @apply absolute;
      @apply -left-4;
      @apply top-1;
    }
  }

  &__label {
    @apply cursor-pointer;
    @apply ml-2;
    @apply py-2;
    @apply text-base;
  }
}
</style>
